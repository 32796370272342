@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500&display=swap);
:root {
  --blue: #3f51b5;
  --black: #0a0a0a;
  --charcoal: #444444;

  --indigo: #3d31c4;
  --ultraviolet: #5b0ce4;
  --mint: #70efde;
}

body {
  margin: 0;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html {
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}

*::selection {
  background-color: var(--black);
  color: #fff;
}

